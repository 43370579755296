body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}
body::-webkit-scrollbar {
  width: 0;
}
:root{
  overflow-y: scroll;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pagination{
  width:max-content;
  padding:5px;
  margin:3% 16px 3% auto !important;
}
.prev,.next{
  border-radius:5px;
  padding:5px 15px !important;
  font-weight:800;
  font-size:18px;
  background:#3a057efb;
  border:none;
  color:#fff;
  transition:1s all ease;
 
}
.prev{
  margin-right: 10px !important;
}
.next{
  margin-left: 10px !important;
}
.prev:hover,.next:hover{
  background:#2c0a4eb2;
}
.edit:hover, .delete:hover{
  cursor:pointer;
  transform: scale(1.2);
}
.edit{
  color:#3f8df2f1;
  transition:1s ease all;
  margin-right: 5px !important;
}
.delete{
  color:#f26659f1;
  transition:1s ease all;
  margin-left: 5px !important;
}
.btn-primary-block{
  padding:10px 15px;
  border:none;
  border-radius:4px;
  background:#63a5fbd5;
  color:#fff;
  font-weight:800;
  text-transform:uppercase;
  transition: 1s ease all;
  width:100%;
}
@media  screen and (max-width:768px) {
  .btn-primary-block{
    padding:10px 5px;
  }
}
.btn-primary-block:hover{
  background:#09254af1;
}
.loader{
  padding:10px 25px;
  border:none;
  margin-top:10px;
  border-radius:4px;
  text-align: center;
  background:#09254af1;
  width:100%;
}
@media  screen and (max-width:768px) {
  .loader{
    padding:10px 10px;
  }
}

.actions{
  padding:5px 13px 10px 13px;
  width:max-content;
  margin-left:auto;
}